import React, { Component } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Linkedin } from "react-feather"
import { Trans } from "gatsby-plugin-react-i18next"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Container from "../../components/Container"
import ClientSection from "../../components/ClientSection"
import ContactCard from "../../components/ContactCard"

import AboutpageDotOrnamentAbove from "../../images/homepage-dot-ornament.png"
import AboutpageDotOrnamentBelow from "../../images/aboutpage-dot-ornament.png"

import "./style.scss"

class AboutPage extends Component {
  render() {
    const { data } = this.props
    const {
      homepageBannerBg,
      teamImage,
      profileWiwit,
      profileChandra,
      profileFebriman,
    } = data

    return (
      <Layout>
        <SEO
          title="About"
          keywords={[
            `provalindonusa`,
            `consultant`,
            `advisory`,
            `properti`,
            `jakarta`,
            `about`,
          ]}
          image={
            homepageBannerBg ? homepageBannerBg.childImageSharp.resize : null
          }
        />
        <div className="aboutpage">
          <section className="aboutpage__title">
            <Container className="aboutpage__title-container">
              <h1>
                <Trans>Tentang Provalindo</Trans>
              </h1>
              <p>
                <Trans>Homepage About Description</Trans>
              </p>
            </Container>
          </section>
          <section className="aboutpage__story">
            <Container className="aboutpage__story-container">
              <img
                src={AboutpageDotOrnamentAbove}
                className="aboutpage__story-ornament-above"
              />
              <Img
                className="aboutpage__story-image"
                fluid={teamImage.childImageSharp.fluid}
              />
              <p>
                <Trans>About Page Story One</Trans>
              </p>
              <p className="mt-32">
                <Trans>About Page Story Two</Trans>
              </p>
              <img
                src={AboutpageDotOrnamentBelow}
                className="aboutpage__story-ornament-below"
              />
            </Container>
          </section>
          <section className="aboutpage__team">
            <Container className="aboutpage__team-container">
              <h2>
                <Trans>Key Person</Trans>
              </h2>
              <div className="aboutpage__team-profile">
                <div className="aboutpage__team-profile-person">
                  <Img
                    className="aboutpage__team-profile-person-image"
                    fluid={profileWiwit.childImageSharp.fluid}
                  />
                  <div className="aboutpage__team-profile-person-desc">
                    <div className="aboutpage__team-profile-person-desc-name">
                      Wiwit Nugroho
                    </div>
                    <div className="aboutpage__team-profile-person-desc-title"></div>
                    <p className="aboutpage__team-profile-person-desc-story">
                      <Trans>Wiwit Story One</Trans>
                      <br />
                      <br />
                      <Trans>Wiwit Story Two</Trans>
                    </p>
                    <a
                      href="https://www.linkedin.com/in/wiwit-nugroho-481477129/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Linkedin color="#1F3961" />
                    </a>
                  </div>
                </div>
                <div className="aboutpage__team-profile-person">
                  <Img
                    className="aboutpage__team-profile-person-image"
                    fluid={profileChandra.childImageSharp.fluid}
                  />
                  <div className="aboutpage__team-profile-person-desc">
                    <div className="aboutpage__team-profile-person-desc-name">
                      Chandra Rambey
                    </div>
                    <div className="aboutpage__team-profile-person-desc-title"></div>
                    <p className="aboutpage__team-profile-person-desc-story">
                      <Trans>Chandra Story One</Trans>
                      <br />
                      <br />
                      <Trans>Chandra Story Two</Trans>
                    </p>
                    <a
                      href="https://www.linkedin.com/in/chandra-rambey-83652598/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Linkedin color="#1F3961" />
                    </a>
                  </div>
                </div>
                <div className="aboutpage__team-profile-person">
                  <Img
                    className="aboutpage__team-profile-person-image"
                    fluid={profileFebriman.childImageSharp.fluid}
                  />
                  <div className="aboutpage__team-profile-person-desc">
                    <div className="aboutpage__team-profile-person-desc-name">
                      Febriman Siregar
                    </div>
                    <div className="aboutpage__team-profile-person-desc-title"></div>
                    <p className="aboutpage__team-profile-person-desc-story">
                      <Trans>Febriman Story One</Trans>
                      <br />
                      <br />
                      <Trans>Febriman Story Two</Trans>
                    </p>
                    <a
                      href="https://www.linkedin.com/in/kjpp-febriman-siregar-dan-rekan-35443a1a2/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Linkedin color="#1F3961" />
                    </a>
                  </div>
                </div>
              </div>
            </Container>
          </section>

          <ClientSection />

          <section className="aboutpage__contact">
            <Container>
              <ContactCard
                title="Contact Title"
                subtitle="Contact Description"
              />
            </Container>
          </section>
        </div>
      </Layout>
    )
  }
}

export default AboutPage

export const aboutPageQuery = graphql`
  query {
    homepageBannerBg: file(
      relativePath: { eq: "images/homepage-banner-bg.png" }
    ) {
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
    teamImage: file(relativePath: { eq: "images/provalindo-team.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    profileWiwit: file(
      relativePath: { eq: "images/provalindo-wiwit-nugroho.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    profileChandra: file(
      relativePath: { eq: "images/provalindo-chandra-rambey.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    profileFebriman: file(
      relativePath: { eq: "images/provalindo-febriman-siregar.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
